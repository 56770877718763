<template>
  <div class="rules">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter">
        <van-icon name="arrow-left" color="#7e7e7e" size="16" />
      </div>
      <div>{{type==1?"积分规则":type==2?"佣金规则":"申诉说明"}}</div>
    </div>
    <div class="msg">

    <div v-html="msg"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: "",
      msg: ""
    };
  },
  methods: {
    handleRouter() {
      this.$router.go(-1);
    },
    async handleruleJf() {
      const data = await this.$api.getRule_jf();
      if (data.data.code === 1) {
        this.msg = data.data.data.content;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleruleYj() {
      const data = await this.$api.getRule_yj();
      if (data.data.code === 1) {
        this.msg = data.data.data.content;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleInstructions() {
      const data = await this.$api.instructions();
      if (data.data.code === 1) {
        this.msg = data.data.data.content;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    }
  },
  mounted() {
    this.type = this.$route.query.type;
    if (this.type == 1) {
      this.handleruleJf();
    } else if (this.type == 2) {
      this.handleruleYj();
    } else {
      this.handleInstructions();
    }
  }
};
</script>
<style lang="scss" scoped>
.rules {
  width: 375px;
  height: 100%;
  padding-top: 40px;
  box-sizing: border-box;
  .msg{
    padding-left:20px;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
</style>